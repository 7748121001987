:root {
  /* Primary */
  --primary-lightest-color: #fff8fe;
  --primary-light-color: #fff6fd;
  --primary-main-color: #ffe6ff;
  --primary-dark-color: #ffa4ff;
  --primary-darkest-color: #ef71e0;

  /* Secondary */
  --secondary-lightest-color: #f3fff5;
  --secondary-light-color: #e4ffe8;
  --secondary-main-color: #bcfec8;
  --secondary-dark-color: #a5fdb0;
  --secondary-darkest-color: #7aca81;

  /* Other */
  --day-off-color: #ffedf5;
  --disabled-grey-dark: #a7a7a7;
  --disabled-grey: #e9e9e9;
  --disabled-grey-light: #fdf4fb;
  --present-day-circle: #f5fff7;
  --festival-light: #dfd1d1e3;
  --festival-text: #5c0000;
  --festival-icons: #886363;

  /* Colors */
  --main-grey: #666;
  --dark-grey: #333;
  --main-black: #000;
  --main-white: #fff;
  --main-red: #ff5050;
  --main-shadow: #0000001a;
  --dark-shadow: #0003;
  --loading-gradient: linear-gradient(180deg, #8a8a8a00 , #8a8a8a3b 5%, #8a8a8a3b 95%, #8a8a8a00 100%);
  --rock-color: #49428e;
  --pop-color: #9d43c4;
  --flamenco-color: #c93828;
  --indie-color: #bb7900;
  --punk-color: #12806a;
  --clasica-color: #70473b;
  --rap-color: #c46812;
  --jazz-color: #2254b1;
  --electronica-color: #0689b1;
  --metal-color: #000;
  --folk-color: #047000;
  --latina-color: #d36600;
  --otros-color: #6d6d6d;
}

/* Variables para el Modo Oscuro */
.dark-theme {
  /* Primary */
  --primary-lightest-color: #1a0c1d;
  --primary-light-color: #2a1b3e;
  --primary-main-color: #4e1d4f;
  --primary-dark-color: #6d2b6d;
  --primary-darkest-color: #9e4d9e;

  /* Secondary */
  --secondary-lightest-color: #162615;
  --secondary-light-color: #1f3a2a;
  --secondary-main-color: #2f6a3d;
  --secondary-dark-color: #40804b;
  --secondary-darkest-color: #609c60;

  /* Other */
  --day-off-color: #1c0a0d;
  --disabled-grey: #4f4f4f;
  --disabled-grey-light: #5e5e5e;
  --present-day-circle: #1f2c1f;
  --festival-light: #2a1d1d;
  --festival-text: #e1bfbf;
  --festival-icons: #6e4a4a;

  /* Colors */
  --main-grey: #aaa;
  --dark-grey: #ddd;
  --main-black: #fff;
  --main-white: #000;
  --main-shadow: #ffffff80;
  --dark-shadow: #ffffffa0;
}
