// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';
@import 'colors';
@import 'dimensions';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
/* stylelint-disable */
@include mat.core();

// Define the theme object.
$_palettes: (
  primary: (
    0: #000,
    10: #30004b,
    20: #481866,
    25: #542572,
    30: #61317e,
    35: #6d3d8b,
    40: #7a4998,
    50: #9562b3,
    60: #b07ccf,
    70: #cc96eb,
    80: #e5b4ff,
    90: #f4d9ff,
    95: #fcecff,
    98: #fff7fc,
    99: #fffbff,
    100: #fff,
  ),
  secondary: (
    0: #000,
    10: #221728,
    20: #382c3e,
    25: #43374a,
    30: #4f4255,
    35: #5b4e61,
    40: #68596e,
    50: #817287,
    60: #9b8ba1,
    70: #b7a5bc,
    80: #d3c0d8,
    90: #efdcf5,
    95: #fcecff,
    98: #fff7fc,
    99: #fffbff,
    100: #fff,
  ),
  tertiary: (
    0: #000,
    10: #331113,
    20: #4c2527,
    25: #593031,
    30: #663b3c,
    35: #734648,
    40: #815153,
    50: #9d6a6b,
    60: #b98384,
    70: #d69c9e,
    80: #f4b7b8,
    90: #ffdada,
    95: #ffedec,
    98: #fff8f7,
    99: #fffbff,
    100: #fff,
  ),
  neutral: (
    0: #000,
    10: #1d1b1e,
    20: #332f33,
    25: #3e3a3e,
    30: #494649,
    35: #555155,
    40: #615d61,
    50: #7a767a,
    60: #958f93,
    70: #afa9ae,
    80: #cbc5c9,
    90: #e8e0e5,
    95: #f6eff3,
    98: #fff7fc,
    99: #fffbff,
    100: #fff,
    4: #100d10,
    6: #151216,
    12: #221f22,
    17: #2c292c,
    22: #373437,
    24: #3c383c,
    87: #dfd8dd,
    92: #ede6eb,
    94: #f3ecf0,
    96: #f9f2f6,
  ),
  neutral-variant: (
    0: #000,
    10: #1f1a21,
    20: #342e36,
    25: #403942,
    30: #4b454d,
    35: #575059,
    40: #635c65,
    50: #7d747e,
    60: #978e98,
    70: #b2a8b2,
    80: #cec3ce,
    90: #eadfea,
    95: #f9edf8,
    98: #fff7fc,
    99: #fffbff,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #fff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($light-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($c-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($c-theme);
// Styles here
html, body { height: 100%; }
body { margin: 0;
  background: linear-gradient(220deg,var(--main-white),var(--primary-lightest-color));
}
.mat-mdc-option .mdc-list-item__primary-text {
  font-family: Raleway!important;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
  font-size:0.9rem!important; 
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: var(--secondary-main-color)!important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: var(--secondary-dark-color)!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #888888!important;
  left: 25%;
  width: 50%;
}

.mdc-text-field__input {
  font-size: 0.9rem!important;
  text-align: end!important;
  width: 95%!important;

  @media (max-width: $breakpoint-lg) {
    text-align: start!important;
    width: 100%!important;
  }
}

.cdk-overlay-pane {
  background-color: var(--main-white);
}

.mdc-text-field {
  padding: 0 8px!important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  visibility: hidden!important;
}

.cdk-overlay-pane {


  &.mat-mdc-dialog-panel {
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: 0px!important;
    max-width: 95vw!important;
    margin: auto;
    background: var(--primary-light-color);
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 700px!important;
  
    .mat-mdc-dialog-surface {
      background: var(--primary-light-color)!important;
    }
  }
}

.toast-container {
  font-family: Raleway, sans-serif;
}

.toast-message {
  margin-top: 5px;
}

.mdc-slider__thumb-knob {
  background-color: red!important;
}


.timepicker__header {
  background-color: var(--primary-darkest-color)!important;
}

.mdc-evolution-chip-set__chips {
  justify-content: end!important;

  @media (max-width: $breakpoint-lg) {
    justify-content: start!important;
  }
}

ngx-material-timepicker-container {
  position: absolute;
  z-index: 1000;
}

/* stylelint-enable */